body {
  margin: 0;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Gilroy-Bold;
  src: url(./fonts/Gilroy-Bold.ttf);
}

@font-face {
  font-family: Gilroy-SemiBold;
  src: url(./fonts/Gilroy-SemiBold.ttf);
}

@font-face {
  font-family: Gilroy-Medium;
  src: url(./fonts/Gilroy-Medium.ttf);
}

@font-face {
  font-family: Gilroy-Regular;
  src: url(./fonts/Gilroy-Regular.ttf);
}

@font-face {
  font-family: CenturyGothic;
  src: url(./fonts/CenturyGothic.ttf);
}

@font-face {
  font-family: Century-Gothic;
  src: url(./fonts/Century-Gothic.ttf);
}

@font-face {
  font-family: Century-Gothic-Bold;
  src: url(./fonts/gothicb.ttf);
}

*,
*:focus,
*:hover {
  outline: none;
}

.dots {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  border-radius: 40px;
  width: 70px;
  text-align: center;
}

.dots li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
  border: 1px solid #1caa9a;
  border-radius: 25px;
}

.dots li button {
  border: none;
  background: #1caa9a;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 18px !important;
  width: 18px !important;
  border-radius: 7.5px;
}

.dots li.slick-active button {
  background-color: #1caa9a;
}

.dots li button:hover,
.dots li button:focus {
  background: #1caa9a;
  outline: 0;
}

li button::before {
  color: #1caa9a !important;
  content: " " !important;
  height: 18px !important;
  width: 18px !important;
}

.slick-list {
  padding-bottom: 35px !important;
}

.join-typography {
  width: 300px;
  height: 52px;
  font-family: 'SF Pro', Roboto, sans-serif;
  font-style: normal;
  font-weight: 510;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #000000;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.join-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 93vh;
}

.join-app-store-typography {
  font-family: 'SF Pro', Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  /* DA/Brand Accent */
  color: #117249;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration-line: none;
}

.join-chevron {
  font-family: 'SF Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  /* DA/Brand Accent */
  color: #117249;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.join-app-store-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.open-app-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.open-app-link {
  margin-left: 8px;
  font-family: 'SF Pro', Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  /* DA/Brand Accent */
  color: #117249;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.error-label {
  color: tomato;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  max-width: 500px;
  text-align: left;
  width: 100%;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
}

@media screen and (max-width: 900px) {
  .css-1elgc8-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-width: 2px !important;
    border-color: #1caa9a !important;
  }
}
